.end-description {
  margin-top: 20px;
  font-style: italic;
  color: #555;
}

.question-hint {
  background-color: #f0f0f0;
  border-left: 4px solid #007bff;
  padding: 10px 15px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.question-hint-title {
  font-weight: bold;
  color: #333;
}

.question-hint p {
  margin: 5px 0;
  font-style: italic;
  color: #555;
}

.question-hint p:first-child {
  font-weight: bold;
  color: #333;
}
